import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section>
      <div className="prose prose-lg dark:prose-dark">
        <h1>Merci!</h1>
        <p>Je reviens vers vous dans les plus brefs délais</p>
      </div>
    </section>
  </Layout>
)
